import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrderTransactionType } from "components/products/ProductType";
import { RootState } from "redux/store";



const initialState:OrderTransactionType = {
    status:0,
    orders:[],
    payments:[]
}

export const orderSlice = createSlice({
    name:"order",
    initialState,
    reducers:{
        assignOrderTransaction(state,action:PayloadAction<OrderTransactionType>){
            state.orders = action.payload.orders
            state.status = action.payload.status
            state.payments = action.payload.payments;
            
            console.log(state.orders)
            console.log(state.payments)
            //console.log(action.payload.orders)
            //console.log(action.payload.payments)
        }
    }
})

export const {assignOrderTransaction} = orderSlice.actions;

export const orderTransactionSlice = (state:RootState) => state.orderTransaction

export default orderSlice.reducer