import Box from "@mui/material/Box";
import Button from "@mui/material/Button/Button";
import Typography from "@mui/material/Typography/Typography";
import { Locale } from "utils/LocaleEN";

const ErrorMessageComponent = () => {
  return (
    <Box
      sx={{
        background: "#FFFFFF",
        border: "1px solid #F9D6D6",
        boxShadow: "inset 16px 0px 0px #F9D6D6",
        borderRadius: 2,
      }}
    >
      <Box sx={{ ml: 1, flex: 1, display: "flex", padding: 3 }}>
        <Box
          flex={1}
          component={"img"}
          sx={{
            content:
              "url('https://imagedelivery.net/LbPLnWIIJf3WvIoMOISJQg/d60f1a88-5118-4582-18b9-48b45e081100/public')",
            justifyContent: "center",
            objectFit: "contain",
            maxWidth: 114,
            margin: 2,
          }}
        />
        <Box
          flex={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{ fontFamily: "IBM Plex Mono", fontWeight: 700, fontSize: 24 }}
          >
            {Locale.general.error.tryAgain.title}
          </Typography>
          <Typography
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {Locale.general.error.tryAgain.message}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              sx={{
                fontFamily: "Plus Jakarta Sans",
                fontWeight: 400,
                fontSize: 16,
                mb: 1,
              }}
              onClick={() => window.location.reload()}
            >
              {Locale.general.error.tryAgain.cta}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ErrorMessageComponent;
