import { NavItems } from "components/homepage/MenuList";


type inputProps = {
    data: NavItems;
    onclick: (data:any)=>void;
    activeitem: NavItems;
};

const MenuLink: React.FC<inputProps> = ({
    data,
    onclick,
    activeitem
})=>{
    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        onclick(data)
    };

    return (
        <li
            className={`${data.class} ${activeitem===data?'active':''}`}
            onClick={handleMenuOpen}
        >{data.title}</li>
    );
}

export default MenuLink;