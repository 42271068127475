import { fetchAddressess } from "api/General";
import AddressForm from "components/address/AddressForm";
import { AddressType } from "components/products/ProductType";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { addressessStore, setAddressess } from "redux/slices/userSlice";

type DisplayAddressForUserProps = {
    address:AddressType
}

const AddressComponent:React.FC = ()=>{

    const [addressArray,setAddressArray] = useState<any[]>([])
    const addresses = useAppSelector(addressessStore)
    const [showAddressForm,toggleAddressForm] = useState(false)
    const [address,setAddress] = useState<AddressType>({})
    const dispatch = useDispatch()
    useEffect(()=>{
        fetchAll()

    },[])

    const fetchAll = async()=>{
        const res = await fetchAddressess({});
        dispatch(setAddressess(res))
    }

    useEffect(()=>{
        let rows:any[] = []
        console.log("Displaying the address")
        console.log(addresses)
        addresses?.forEach((el,index)=>{
            rows.push(<DisplayAddressForUser address={el} key={index}/>)
        })
        setAddressArray(rows)
    },[addresses])

    const DisplayAddressForUser:React.FC<DisplayAddressForUserProps> = ({address}) => {
        return (
            <div className="address-item">
                <div className="address-icon1">
                    <i className="uil uil-map-marker"></i>
                </div>
                <div className="address-dt-all">
                    <h4></h4>
                    <p>{address.houseno},{address.street},{address.locality}, {address.city},{address.state}, {address.country}</p>
                    <ul className="action-btns ">
                        <li><div className="site-btn2"
                            onClick={()=>{
                                setAddress(address)
                                toggleAddressForm(true)
                            }}
                        ><i className="uil uil-edit "></i>Edit</div></li>
                        <li><a href="#" className="action-btn"><i className="uil uil-trash-alt"></i></a></li>
                    </ul>
                </div>
            </div>
        )
    }

    return (
        <div className="col-xl-9 col-lg-8 col-md-12">
            <div className="dashboard-right">
                <div className="row">
                    <div className="col-md-12">
                        <div className="main-title-tab">
                            <h4><i className="uil uil-location-point"></i>My Address</h4>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="pdpt-bg">
                            <div className="pdpt-title">
                                <h4>New Address</h4>
                            </div>
                            <div className="address-body">
                                {!showAddressForm &&
                                    <div className="add-address hover-btn" data-bs-toggle="modal" 
                                        data-bs-target="#address_model" onClick={
                                            ()=>{
                                                setAddress({})
                                                toggleAddressForm(true)
                                            }
                                        
                                        }>Add New Address   
                                    </div>
                                }
                                {!showAddressForm && addressArray}
                                {showAddressForm && 
                                    <AddressForm
                                        address={address}
                                        closeFunction={toggleAddressForm}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default AddressComponent;