import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { CartItem, finalamountSlice, items, setResetItems } from "redux/slices/cartSlice";
import { LocalRoutes } from "utils/LocalRoutes";
import { getLocalStorageData } from "utils/LocalStorage";
import { assignLoggedInUser, loggedInUserStore } from "redux/slices/userSlice";
import CartRow from "./CartRow";

export type CartRowType = {
    cartrow:CartItem;
}



const CartItemComponent:React.FC = ()=>{

    const cartItems = useAppSelector(items)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const finalamount = useAppSelector(finalamountSlice)
    const loggedInUser = useAppSelector(loggedInUserStore)
    const [cartRowArray,setCartRowArray] = useState<any[]>(
        
        /*cartItems.map((el,index)=>{
            <CartRow
                key={index}
                cartrow={el}
            />
        })*/
    )
    
    useEffect(()=>{
        console.log(loggedInUser.loggedInUser.id)
        console.log("Hello")
        console.log(cartItems);
        let rows:any[] = [];
        cartItems.forEach((el,index)=>{
            rows.push(<CartRow
                key={index}
                cartrow={el}
            />)
        })

        /*cartItems.map((el,index)=>{
            rows.push(<CartRow
                key={index}
                cartrow={el}
            />)
        })*/
        console.log(rows)
        setCartRowArray(rows)
        /*if(cartItems.length==0){
            
            let localcart = getLocalStorageData("cart")
            if(localcart){
                localcart = Object.values(localcart);
                if(localcart.length>0)
                    dispatch(setResetItems(localcart))
            }
            

        }*/

    },[
        cartItems
    ])
    

    return (
        <section className="shop-cart spad">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="shop__cart__table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                    <th>Total</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {cartRowArray}
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        
            <div className="row">
                <div className="col-lg-6">
                    {/* <div className="discount__content">
                        <h6>Discount codes</h6>
                        <form action="#">
                            <input type="text" placeholder="Enter your coupon code"/>
                            <button type="submit" className="site-btn">Apply</button>
                        </form>
                    </div> */}
                </div>
                <div className="col-lg-4 offset-lg-2">
                    <div className="cart__total__procced">
                        <h6>Cart total</h6>
                        <ul>
                            <li>Subtotal <span>{finalamount}</span></li>
                            <li>Total <span>{finalamount}</span></li>
                        </ul>
                        { loggedInUser.loggedInUser.id!=null ?
                        <div className="primary-btn" onClick={
                            ()=>{
                                console.log(loggedInUser.loggedInUser.id)
                                navigate(LocalRoutes.general.checkout)
                            }
                        }>Proceed to checkout</div>
                        :<div className="primary-btn" onClick={
                            ()=>{
                               
                                navigate(LocalRoutes.general.login)
                            }
                        }>Proceed to checkout</div>
                    }
                    </div>
                </div>
            </div>
        </div>
        </section>
    )
}

export default CartItemComponent;