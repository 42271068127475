import React from "react";

const MainLayout: React.FC = (props)=>{


    return (
        <div>
            {props.children}
            
        </div>
    );
}

export default MainLayout;