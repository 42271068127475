import { Box } from "@mui/material";
import ErrorMessageComponent from "components/error/ErrorMessageComponent";
import MainLayout from "layout/MainLayout";
import { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { relatedProductsSlice, selectedProductSlice, updateProduct, updateRelatedProducts } from "redux/slices/productSlice";
import { Locale } from "utils/LocaleEN";
import { isEmpty } from "lodash";
import NotFoundComponent from "components/error/NotFoundComponent";
import ProductDetailComponent from "components/productdetail/ProductDetailComponent";
import { useParams } from "react-router-dom";
import Header from "components/header/Header";
import { useDispatch } from "react-redux";
import { fetchProduct, fetchRelatedProduct } from "api/General";
import BreadCrumb from "components/productdetail/BreadCrumb";
import ProductTabDescription from "components/productdetail/ProductTabDescription";
import RelatedProducts from "components/productdetail/RelatedProducts";
import Footer from "components/footer/Footer";

const ProductDetail: React.FC = () => {
    /*useEffect(() => {
      trackPageView(PageCategory.registration, Routes.general.login);
    }, []);
    */
    const [loading, toggleLoading] = useState(true);
    const [err, setError] = useState<any>();
    const selectedProduct = useAppSelector(selectedProductSlice)
    const relatedProducts = useAppSelector(relatedProductsSlice)
    const dispatch = useDispatch()


    const fetchProductDetail = async ()=>{
      console.log("Showing paramas")
      console.log(params)
      const payload = {producturl:params.producturl};
      const res = await fetchProduct(payload)
      dispatch(updateProduct({product:res.product}))
      toggleLoading(false)
    }

    const getRelatedProducts = async ()=>{
      const payload = {producturl:params.producturl};
      const res = await fetchRelatedProduct(payload)
      dispatch(updateRelatedProducts({relatedProducts:res.related}))
      toggleLoading(false)
    }

    //const selectedProduct = 
    const params = useParams();
    
    console.log(selectedProduct)
    useEffect(()=>{
      console.log("Checking the selected product")
      if(isEmpty(selectedProduct)){
        //fetch the selected product by the url
        console.log("Selecting the product")
        
        fetchProductDetail();
        
        
        //fetch the product
        //fetch the selected product
        
      } else {
        //check if the url is same as of the product
        console.log("In other part")
        console.log(selectedProduct)
        console.log(selectedProduct)
        if(selectedProduct?.url===params.producturl){
          console.log("Product url is same as url")
          //fetch the other details like related products
        }
        
        toggleLoading(false)
      }
      getRelatedProducts()
    },[]);

    const renderData = () => {
      return (
        <>
          <BreadCrumb></BreadCrumb>
          <ProductDetailComponent></ProductDetailComponent>
          <ProductTabDescription></ProductTabDescription>
          <RelatedProducts></RelatedProducts>
        </>
      );
    };

    return (
      <MainLayout>
        <Header></Header>
        {loading ? (
          <Box>{Locale.general.product.loading}</Box>
        ) : err ? (
          <ErrorMessageComponent />
        ) : (
          <>{isEmpty(selectedProduct) ? <NotFoundComponent /> : renderData()}</>
        )}
        <Footer></Footer>
      </MainLayout>
    );
  };
  
//export default profiler(LoginPage);
export default ProductDetail;