import { useAppSelector } from "redux/hooks";
import { banners } from "redux/slices/bannerSlice";

const CenterTextSlider: React.FC = () => {
  const banner = useAppSelector(banners);
  const centerbanner = banner.find((el) => el.bannertypeid === 3);

  return (
    <section
      className="banner set-bg"
      style={{
        backgroundImage: `url(` + centerbanner?.images[0]?.fullurl + `)`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-8 m-auto">
            <div className="banner__slider owl-carousel owl-loaded owl-drag">
              <div className="banner__item">
                <div className="banner__text">
                  <span>The Chloe Collection</span>
                  <h1>The Project Jacket</h1>
                  <a href="#">Shop now</a>
                </div>
              </div>
            
            </div>
          </div>
        </div>
      </div>
    </section>
    
  );
};

export default CenterTextSlider;
