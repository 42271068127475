import CenterTextSlider from "components/banner/CenterTextSlider";
import FullHorizontalHighlights from "components/banner/FullHorizontalHighlights";
import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import ProductSection from "components/homepage/productsection/ProductSection";
import TopSection from "components/homepage/topsection/TopSection";
import InstagramSlider from "components/intagram/InstagramSlider";
import ColumnsDisplay from "components/products/ColumnsDisplay";
import MainLayout from "layout/MainLayout";
import {products,
    updateProducts,
} from "redux/slices/homepageProductsSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { fetchHomePageProducts } from "api/General";
import { useEffect } from "react";

const HomePage : React.FC = () => {

    let fetchFlag = false
    const homepageProducts = useAppSelector(products);
    const dispatch = useAppDispatch()
    const triggerHomePageProducts =async () => {
        if(!fetchFlag){
            fetchFlag=true
            if(homepageProducts==null || homepageProducts.length==0){
                const res: any = await fetchHomePageProducts();
                //console.log(res)
                if (res?.success) {
                    console.log("Calling the reducer")
                    console.log(res.data)
                    //setBanners(res.data)
                    dispatch(updateProducts({ products: res.data.products }));
                    
                    
                    
                } else {
                    console.log("show error here");
                }
            }
            fetchFlag=false;
        }
        
    }

    useEffect( () => {
        triggerHomePageProducts();
    }, []);


    return (
        <MainLayout>
            <Header></Header>
            <TopSection></TopSection>
            <ProductSection></ProductSection>
            <CenterTextSlider></CenterTextSlider>
            <ColumnsDisplay></ColumnsDisplay>
            <FullHorizontalHighlights></FullHorizontalHighlights>
            <InstagramSlider></InstagramSlider>
            <Footer></Footer>
        </MainLayout>
        
    );
}

export default HomePage;