import { ReactElement, useEffect, useState } from "react";
import { fetchBanners } from 'api/General'
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { banners, updateBanners } from "redux/slices/bannerSlice";
import { siteData } from "data";


type BannerImageType = {
    imageSequence: number;
    bannerType:number;
    width:number;
    height:number;
    bannerposition:number;
};

type ImageModel = {
    fullurl:string;
    width:number;
    height:number;
}

export type BannerType = {
    sequence: number;
    title: string;
    description: string;
    hyperlink: string;
    images:ImageModel[];
    bannertypeid:number;
}

const TopSection : React.FC = () => {

    //const [banners, setBanners] = useState<BannerType[]>([]);
    //const [fetchFlag, setFetchFlag] = useState(false);
    const dispatch = useAppDispatch()
    const storeBanners = useAppSelector(banners)
    let fetchFlag = false
    useEffect( () => {
        triggerBanners();
    }, []);

    const triggerBanners =async () => {
        
        console.log("stage1:"+fetchFlag)
        if((storeBanners==null || storeBanners.length===0) && !fetchFlag){
            fetchFlag = true;
            //setFetchFlag(true)
            console.log("stage2:"+fetchFlag)
            const res: any = await fetchBanners();
            //console.log(res)
            if (res?.success) {
                //console.log(res.data)
                //setBanners(res.data)
                dispatch(updateBanners({banners:res.data}))
                /*await res.data.forEach(await function(item:any){
                    setBanners((prevUsers) => prevUsers.concat({sequence: item['sequence'],
                        title: item['title'],
                        description: item['description'],
                        hyperlink: item['hyperlink'],
                        images:item['images'].length>0?item['images'][0]:null,
                        bannertypeid:item['bannertypeid'],
                    }))
                    
                });*/
                
                
            } else {
                console.log("show error here");
            }
            
            fetchFlag = false;
            //setFetchFlag(false)
            console.log("stage3:"+fetchFlag)
        }
    }

    const BannerElement = (props: BannerImageType): ReactElement => {
        let localClassName = "categories__item set-bg"
        if(props.bannerposition===1){
            localClassName = localClassName + " categories__large__item"
        }
        //console.log(props.imageSequence+":"+props.bannerType)
        
        const bannerObject = storeBanners.find(function(block){
            if(block.sequence === props.imageSequence 
                && block.bannertypeid === props.bannerType){
                    return true
                }
                return false
            });
        //console.log(bannerObject)
        const backgroundImage = bannerObject?.images.find(el=>{
            if(el.width===props.width && el.height===props.height){
                return true;
            }
            return false
        })
        
        return (
           
            <div className={localClassName} 
                style={{backgroundImage: `url(`+backgroundImage?.fullurl+`)`}}
                >
                
                <div className="categories__text">
                    <h1>
                        {bannerObject?.title}</h1>
                    <p>{bannerObject?.description}</p>
                    <a href={bannerObject?.hyperlink}>Shop now</a>
                </div>
            </div>
        );
        
    };


    return (
        <section className="categories">
            
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 p-0">
                        <BannerElement bannerType={siteData.config.banner1} imageSequence={1} width={638} height={955} bannerposition={1}/>
                    </div>
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 p-0">
                                <BannerElement bannerType={siteData.config.banner2} imageSequence={1} width={472} height={314} bannerposition={2}/>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 p-0">
                                <BannerElement bannerType={siteData.config.banner2} imageSequence={2} width={472} height={314} bannerposition={2}/>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 p-0">
                                <BannerElement bannerType={siteData.config.banner2} imageSequence={3} width={472} height={314} bannerposition={2}/>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 p-0">
                                <BannerElement bannerType={siteData.config.banner2} imageSequence={4} width={472} height={314} bannerposition={2}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default TopSection;