import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
//import registrationSlice from "redux/slices/registration/userRegistrationSlice";
//import userSlice from "redux/slices/sessionSlice";
import logger from "redux-logger";
import { Constants } from "utils/Constants";
import homepageProductsSlice from "./slices/homepageProductsSlice";
import bannerSlice from "./slices/bannerSlice";
import productSlice from "./slices/productSlice";
import cartSlice from "./slices/cartSlice";
import orderSlice from "./slices/orderSlice";
import loggedInUserTypeSlice from "./slices/userSlice";
import ordersSlice from "./slices/ordersSlice";
import categorySlice from "./slices/categorySlice";
//import { Constants } from "utils/Constants";

const reducers = combineReducers({
  //registration: registrationSlice,
  //user: userSlice,
  
  homepageProducts: homepageProductsSlice,
  banners: bannerSlice,
  selectedproduct: productSlice,
  cart: cartSlice,
  orderTransaction: orderSlice,
  orders: ordersSlice,
  loggedInUserStore: loggedInUserTypeSlice,
  categoryProducts: categorySlice
});

const persistConfig = {
  timeout: 10, //Set the timeout function to a minimum time
  key: "root",
  version: 1,
  storage: storage,
  whitelist: ["registration", "user"], // only navigation will be persisted
};

export const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    process.env.REACT_APP_ENV === Constants.environment.development
      ? getDefaultMiddleware({
          serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
          },
        }).concat(logger)
      : getDefaultMiddleware({
          serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
          },
        }), // log only in dev
  devTools: process.env.REACT_APP_ENV === Constants.environment.development,
});

let persistor = persistStore(store);

export { store, persistor };
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
