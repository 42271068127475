
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductType } from "components/products/ProductType";
import { RootState } from "redux/store";

export type SelectedCategory = {
    products?:ProductType[];
}

const initialState:SelectedCategory = {
    products:[]
}

export const categorySlice = createSlice({
    name:"selectedProduct",
    initialState,
    reducers:{
        setProducts(state,action:PayloadAction<SelectedCategory>){
            state.products = action.payload.products;
        },
    }
})
export const {
    setProducts
} = categorySlice.actions

export const categoryProductsSlice = (state : RootState) => state.categoryProducts.products  || []

export default categorySlice.reducer