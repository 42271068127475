import Footer from "components/footer/Footer"
import Header from "components/header/Header"
import OrderSuccessComponent from "components/order/OrderSuccessComponent"
import MainLayout from "layout/MainLayout"


const OrderSuccessPage:React.FC = ()=>{
    return (
        <MainLayout>
            <Header></Header>
            <OrderSuccessComponent></OrderSuccessComponent>
            <Footer></Footer>
        </MainLayout>
    )
}

export default OrderSuccessPage;