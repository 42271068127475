import React from 'react';
//import ReactDOM from 'react-dom';
import ReactDOM from "react-dom/client";
import './index.css';
import './assets/template/css/bootstrap.min.css';
import './assets/template/css/elegant-icons.css';
import './assets/template/css/font-awesome.min.css';
//import './assets/template/css/jquery-ui.min.css';
import './assets/template/css/magnific-popup.css';
//import './assets/template/css/owl.carousel.min.css';
import './assets/template/css/slicknav.min.css';
import './assets/template/css/style.css';
import './assets/css/mystyle.css';
import './assets/template2/style.css';
//import './assets/template/js/jquery-3.3.1.min.js';
//import './assets/template/js/bootstrap.min.js';
//-----import './assets/template/js/jquery.magnific-popup.min.js';
//import './assets/template/js/jquery-ui.min.js';
//import './assets/template/js/mixitup.min.js';
//import './assets/template/js/jquery.countdown.min.js';
//import './assets/template/js/jquery.slicknav.js';
//import './assets/template/js/owl.carousel.min.js';
//import './assets/template/js/jquery.nicescroll.min.js';
//import './assets/template/js/main.js';
//import App from './App';
//import reportWebVitals from './reportWebVitals';
import App from "./App";
import { Provider } from 'react-redux';
import persistStore from 'redux-persist/es/persistStore';
import { store } from "redux/store";

//const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <Provider store={store}>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </Provider>
);
/*ReactDOM.render(
  <React.StrictMode>
    { <Provider store={store}> }
      { <PersistGate loading={null} persistor={persistor}> }
        <App />
      { </PersistGate> }
    { </Provider> }
  </React.StrictMode>,
  document.getElementById("root")
);
*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
