import { ProductType } from "components/products/ProductType";
import { ReactElement } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { categoryProductsSlice } from "redux/slices/categorySlice";
import { updateProduct } from "redux/slices/productSlice";

type ProductProps = {
    product:ProductType;
    imageWidth:number;
    imageHeight:number
}

const CategoryProducts : React.FC = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const BigProductCard = (props:ProductProps): ReactElement =>{



        const navigateToProduct = ()=>{
            dispatch(updateProduct({product:props.product}))
            navigate("/"+props.product?.url)
        }

        const imageobj = props.product?.images?.find(function(item:any){
            if(item.width===props.imageWidth && item.height===props.imageHeight){
                return true
            }
            return false
        })

        return (

            <div className="col-lg-4 col-md-6">
                <div className="product__item"  onClick={()=>{
                    navigateToProduct()
                }}>
                    <div className="product__item__pic set-bg">
                        <img src={imageobj ? imageobj.fullurl:'none'} alt="" />
                        <div className="label new">New</div>
                        <ul className="product__hover">
                            <li><a href={
                        imageobj ? imageobj.fullurl: '#'
                    } className="image-popup"><span className="arrow_expand"></span></a></li>
                            <li><a href="#"><span className="icon_heart_alt"></span></a></li>
                            <li><a href="#"><span className="icon_bag_alt"></span></a></li>
                        </ul>
                    </div>
                    <div className="product__item__text">
                        <h6><a href="#">{props.product.name}</a></h6>
                        <div className="rating">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                        <div className="product__price">{props.product.sp}</div>
                    </div>
                </div>
            </div>
        )
    }

    const CategoryProduct = () : ReactElement => {
        
        const products = useAppSelector(categoryProductsSlice)

        return (
                <div className="row">
                {products.map((row:ProductType)=>(
                    <BigProductCard
                        key={row.id}
                        product={row}
                        imageWidth={270}
                        imageHeight={360}
                    />
                ))}
                </div>
        )
    }


    return (
        <div className="col-lg-9 col-md-9">
            <CategoryProduct/>
        </div>
    );
}

export default CategoryProducts;