import { getExpiryTime } from "./Time";
import { Constants } from "utils/Constants";
import { decryptAESData, decryptAESString, encryptAESData, encryptAESString } from "./AESFunctions";
//import { logException } from "./Sentry";

export const setLocalStorageString = (keyName: string, data: string) => {
  const encryptedData =
    process.env.REACT_APP_ENV === Constants.environment.development
      ? data
      : encryptAESString(data);
  try {
    window.localStorage[keyName] = encryptedData;
    return true;
  } catch (error) {
    //logException(error);
  }
  return false;
};

export const getLocalStorageString = (
  type: string = Constants.localStorage.session
) => {
  try {
    const encryptedData = window.localStorage.getItem(type);
    if (encryptedData) {
      const decryptedData =
        process.env.REACT_APP_ENV === Constants.environment.development
          ? encryptedData
          : decryptAESString(encryptedData);
      return decryptedData;
    }
  } catch (error) {
    //logException(error);
  }
  return null;
};

export const setLocalStorageData = (keyName: string, data: any) => {
  let payload = data ? { ...data } : null;
  if (
    (keyName === Constants.localStorage.session) &&
    data
  ) {
    const expiry = getExpiryTime(data.expires_in);
    payload = { ...payload, expiry };
  }

  const encryptedData =
    process.env.REACT_APP_ENV === Constants.environment.development
      ? JSON.stringify(payload)
      : encryptAESData(payload);
  try {
    window.localStorage[keyName] = encryptedData;
    return true;
  } catch (error) {
    //logException(error);
  }
  return false;
};

export const getLocalStorageData = (
  type: string = Constants.localStorage.session
) => {
  try {
    const encryptedData = window.localStorage.getItem(type);
    if (encryptedData) {
      const decryptedData =
        process.env.REACT_APP_ENV === Constants.environment.development
          ? JSON.parse(encryptedData)
          : decryptAESData(encryptedData);
      return decryptedData;
    }
  } catch (error) {
    //logException(error);
  }
  return null;
};
