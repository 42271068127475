import { CartItem } from "redux/slices/cartSlice";

export const getCartPayload = (items:CartItem[]) => {
    let cartItems:any = []
    items.forEach(function(item){
        let cartItem:any = {}
        cartItem['qty']=item.qty
        cartItem['product_id']=item.product.id
        cartItem['vendorid']=0
        cartItem['field1']=''
        cartItem['product']={};
        cartItem['product']['sp']=item.product.sp
        cartItem['product']['canbeclubbed']=1
        cartItems.push(cartItem);
    })
    return cartItems;
};