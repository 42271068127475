import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setResetItems } from "redux/slices/cartSlice";
import { fetchOrderTransaction }  from "api/General";
import { OrderItemType, OrderType, PaymentType } from "components/products/ProductType";
import { assignOrderTransaction, orderTransactionSlice } from "redux/slices/orderSlice";
import { useAppSelector } from "redux/hooks";
import moment from "moment";

type OrderProps={
    order:OrderType
}

type OrderItemProps = {
    orderItem:OrderItemType
}

type PaymentItemProps = {
    payment:PaymentType;
}

const OrderSuccessComponent:React.FC = ()=>{

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const search = useLocation().search;
    const orderTransaction = useAppSelector(orderTransactionSlice)
    const [orderElements,setOrderElements] = useState<any[]>([])
    const [payments,setPayments] = useState<any[]>([])
    const [showdetails,setshowdetails]= useState(false);
    let loadFlag = false;
    useEffect(()=>{
        if(!loadFlag){
            loadFlag = true
            
            console.log("a");
            fetchData();
        }
    },[])

    useEffect(()=>{
        console.log(orderTransaction)
        let rows:any[] = [];
        orderTransaction?.orders?.forEach((el,index)=>{
            rows.push(
                <OrderElement key={index}
                    order={el}
                />
            )
        })
        setOrderElements(rows)
        rows=[];
        orderTransaction?.payments?.forEach((el,index)=>{
            rows.push(
                <PaymentElement key={index}
                    payment={el}
                />
            )
        })
        setPayments(rows)
    },[orderTransaction])

    const fetchData = async ()=>{
        const id = new URLSearchParams(search).get("ordertransactionid")
        const res = await fetchOrderTransaction({ordertransactionid:id})
        /*setOrderTransaction(res);
        console.log("After fetching")
        console.log(res)
        console.log(orderTransaction)
        let localOrderTransaction:OrderTransactionType = res as OrderTransactionType
        console.log(localOrderTransaction)
        console.log(orderTransaction)
        */
        loadFlag = false
        console.log("Hello");
        if(res.length>0){
            
            console.log(res[0])
            console.log(res[0]['ordertransaction'])
            dispatch(assignOrderTransaction(res[0]))
            dispatch(setResetItems([]))
        }
        
    }
    
    const PaymentElement:React.FC<PaymentItemProps> = ({payment})=>{
        return (
            <div className="order-details">
                <div><b>Mode :</b><span style={{color:"red",fontWeight:"bold"}}>{payment.mode==0?"Cash on Delivery":"PayPal"}</span></div>
                <div><b>Amount :   </b><span style={{color:"red",fontSize:"20px",fontWeight:"bolder"}}>  {payment.amount}</span></div>
                
            </div>
        )
    }

    const OrderItemElement:React.FC<OrderItemProps> = ({orderItem})=>{
        return (
                
            <tr style={{textAlign:"start"}}>
                <td>{orderItem.product.name}</td>
                <td>{orderItem.quantity}</td>
                <td>{orderItem.product.sp}</td>
            </tr>
                  
        )
    }

    const OrderElement:React.FC<OrderProps> = ({order})=>{
        const [orderItemElements,setOrderItemElements] = useState<any[]>([])

        useEffect(()=>{
            let rows:any[] = [];
            order?.items?.forEach((el,index)=>{
                rows.push(
                    <OrderItemElement key={index}
                        orderItem={el}
                    />
                )
            })
            setOrderItemElements(rows)
        },[order])

        return (
            <div>
                <div className="order-details">
                    <div><b>Order Number #:</b>&nbsp;{order.ordernumber}</div>
                    <div><b>Delivery Date:</b>&nbsp;{moment(order.deliverydate).format('DD-MM-YYYY')}</div>
                    <div><b>Order Status:</b>&nbsp;{order.status==0?"Pending":"Delivered"}</div>
                    {/*order.items?.forEach((el)=>{
                        <OrderItemElement orderItem={el}></OrderItemElement>
                    })*/}
                </div>
                <h4 style={{marginTop:"20px"}}>Shipment Details</h4>
                <div className="order-details">
                    <table>
                        <thead>
                            <tr style={{textAlign:"start",fontSize:"14px",fontWeight:"bold"}}>
                                <th>Product</th>
                                <th>Quantity</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        <tbody>{orderItemElements}</tbody>
                    </table>
                </div>
            </div>

        )
    }
    

    return (
        <div className="col-md-12" style={{textAlign:"center"}}>
            <section className="spad">
                <div className="order-confirm">
                    <h4 style={{color:"green",fontSize:"25px"}}>Your Order has been Placed. <i className='bx bx-check-circle'></i></h4>
                    <div className="confirm-text">
                        <b>Thank you for shopping with us!</b> <br/>Your Order is confirmed. Our team is working
                        on your shipment and we will notify you when your items are packed an ready to go. {orderTransaction.status}

                        The summary of the order is as below:
                    </div>
                    <div className="primary-btn" onClick={() => { setshowdetails(!showdetails) }}> 
                        Show order details
                    </div>
                </div>
                    { showdetails &&
                        <div className="order-confirm">
                            <h4 style={{marginTop:"30px"}}>Order Details</h4>
                        
                            {orderElements}
                            
                            <div className="cart__total__procced">
                                <h6>Payment Information</h6>
                                {payments}
                            </div>
                            
                        </div>
                    }
                    
                
            </section>
        </div>
    )
}

export default OrderSuccessComponent;