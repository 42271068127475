import { fetchAddressess, generateOrder, postPaymentStatus } from "api/General";
import SideCart from "components/cart/SideCart";
import { AddressType } from "components/products/ProductType";
import { useEffect, useRef, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { finalamountSlice, items, setResetItems } from "redux/slices/cartSlice";
import { assignOrderTransaction } from "redux/slices/orderSlice";
import {
	addressessStore,
	loggedInUserStore,
	setAddressess,
} from "redux/slices/userSlice";
import { getCartPayload } from "utils/Cart";
import { loadScript } from "utils/LoadScript";
import { LocalRoutes } from "utils/LocalRoutes";
import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { siteData } from "data";

interface IPaymentOptions {
	mode: number;
	amount: number;
}

interface IFormInput {
	firstName: string;
	lastName: string;
	age: number;
	shippinghouseno: string;
	shippingstreet: string;
	shippinglocality: string;
	shippingcity: string;
	shippingstate: string;
	shippingzipcode: string;
	shippingmobile: string;
	//paymentEntries: IPaymentOptions;
}

declare global {
	interface Window {
		Razorpay: any;
	}
}

const CheckoutComponent: React.FC = () => {
	const [selectedAddressId, setSelectedAddressId] = useState<number>(0);
	const [selectedAddress, setSelectedAddress] = useState<AddressType>({});
	const [paymentMode, setPaymentMode] = useState<number>(0);
	const addressRef = useRef<AddressType>({});
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
	} = useForm<IFormInput>();
	const [addcartsnack, setaddcartsnack] = useState(false);
	const handleCloseSnack = () => {
		setaddcartsnack(false);
	}
	const cartItems = useAppSelector(items);
	const finalamount = useAppSelector(finalamountSlice);

	const onSubmit: SubmitHandler<IFormInput> = async (data) => {
		console.log(data);
		//send the data to the server and create the order
		const res = await loadScript(
			"https://checkout.razorpay.com/v1/checkout.js"
		);
		if (!res) {
			alert("Razorpay SDK failed to load. Are you online?");
			return;
		}
		let param: any = { ...data };
		param["paymententries"] = [{ mode: paymentMode, amount: finalamount }];
		console.log("cart items");
		console.log(cartItems);
		param["cartitems"] = getCartPayload(cartItems);
		const result = await generateOrder(param);
		if (!result && result.status) {
			alert("Server error. Are you online?");
			return;
		}
		console.log(result.pendingpayment);
		if (paymentMode == 0) {
			<Snackbar open={addcartsnack} autoHideDuration={6000} onClose={handleCloseSnack}>
				<Alert onClose={handleCloseSnack} variant="filled" severity="success" sx={{ width: '100%' }}>
					Order Has been placed successfully
				</Alert>
			</Snackbar>
			navigate({
				pathname: LocalRoutes.general.confirmOrder,
				search: "?ordertransactionid=" + result.ordertransactionid,
			});
		} else if (paymentMode == siteData.razorpayid.id) {
			console.log(result.pendingpayment.razorpay);
			const {
				amount,
				id: order_id,
				currency,
			} = result.pendingpayment["razorpay"];

			const options = {
				key: "rzp_test_RzgXva5mYeb0gt", // Enter the Key ID generated from the Dashboard
				amount: amount.toString(),
				currency: currency,
				name: user?.loggedInUser?.firstname ?? ""+" "+ user?.loggedInUser?.lastname ?? "",
				description: "Test Transaction",
				//image: { logo },
				order_id: order_id,
				handler: async function (response: any) {
					console.log(response);
					const data = {
						orderCreationId: order_id,
						razorpayPaymentId: response.razorpay_payment_id,
						razorpayOrderId: response.razorpay_order_id,
						razorpaySignature: response.razorpay_signature,
					};
					const result = await postPaymentStatus(data);
					dispatch(assignOrderTransaction(result["ordertransaction"]));
					//const result = await axios.post("http://localhost:5000/payment/success", data);
					console.log(result);
					//alert(result.data.msg);

					//navigate to the confirmation page
					navigate({
						pathname: LocalRoutes.general.confirmOrder,
						search: "?ordertransactionid=" + result["ordertransaction"]["id"],
					});
				},
				prefill: {
					name: user?.loggedInUser?.firstname ?? "",
					email: user?.loggedInUser?.email ?? "",
					contact: 99999999,
				},
				notes: {
					address: "Soumya Dey Corporate Office",
				},
				theme: {
					color: "#61dafb",
				},
			};
			const paymentObject = new window.Razorpay(options);
			paymentObject.open();
		}
	};
	const addresses = useAppSelector(addressessStore);
	const user: any = useAppSelector(loggedInUserStore);

	useEffect(() => {
		if (user?.accessToken) fetchAll();
		console.log("Triggered");
		if (user?.loggedInUser?.id > 0) {
			setValue("firstName", user?.loggedInUser?.firstname ?? "");
			setValue("lastName", user?.loggedInUser?.lastname ?? "");
		}
	}, []);

	const fetchAll = async () => {
		const res = await fetchAddressess({});

		dispatch(setAddressess(res));
	};
	useEffect(() => {
		if (addresses) {
			console.log(addresses);
			let address = addresses?.find((el) => el.id === selectedAddressId);
			setValue("shippinghouseno", address?.houseno ?? "");
			setValue("shippingstreet", address?.street ?? "");
			setValue("shippinglocality", address?.locality ?? "");
			setValue("shippingcity", address?.city ?? "");
			setValue("shippingstate", address?.state ?? "");
			setValue("shippingzipcode", address?.zipcode ?? "");
		}
	}, [selectedAddressId]);
	const BillingForm: React.FC = () => {
		return (
			<div className="col-lg-8">
				<h5>Billing detail</h5>
				<div className="row">
					<div className="col-lg-12">
						<div className="col-md-12">
							<div className="checkout__form__input">
								<p>
									First Name <span>*</span>
								</p>
								<input
									{...register("firstName", { required: true, maxLength: 20 })}
								/>
								{errors.firstName?.type === "required" &&
									"First name is required"}
							</div>
						</div>
						{user?.loggedInUser?.id && (
							<select
								onChange={(e) => {
									setSelectedAddressId(parseInt(e.target.value));
									//console.log(e.target.value)
									//setSelectedAddress(addresses?.find(el=>el.id===parseInt(e.target.value))??null)
								}}
								value={selectedAddressId}
							>
								{addresses && addresses?.length > 0 ? (
									addresses.map((address, index) => (
										<option key={index} value={address.id}>
											{address.houseno},{address.street},{address.locality}
											{address.city},{address.state},{address.zipcode}
										</option>
									))
								) : (
									<></>
								)}
							</select>
						)}

						<div className="col-md-12">
							<div className="checkout__form__input">
								<p>
									Last Name <span>*</span>
								</p>
								<input
									{...register("lastName", { required: true, maxLength: 20 })}
								/>
								{errors.lastName?.type === "required" &&
									"Last name is required"}
							</div>
						</div>

						<div className="col-lg-12">
							<div className="checkout__form__input">
								<p>
									House No <span>*</span>
								</p>
								<input
									defaultValue={selectedAddress?.id}
									{...register("shippinghouseno", {
										required: true,
										maxLength: 20,
									})}
								/>
								{errors.shippinghouseno?.type === "required" &&
									"First name is required"}
							</div>
							<div className="checkout__form__input">
								<p>
									Street <span>*</span>
								</p>
								<input
									defaultValue={selectedAddress?.street}
									{...register("shippingstreet", {
										required: true,
										maxLength: 20,
									})}
								/>
								{errors.shippingstreet?.type === "required" &&
									"First name is required"}
							</div>
							<div className="checkout__form__input">
								<p>
									Locality <span>*</span>
								</p>
								<input
									defaultValue={selectedAddress?.locality}
									{...register("shippinglocality", {
										required: true,
										maxLength: 20,
									})}
								/>
								{errors.shippinglocality?.type === "required" &&
									"First name is required"}
							</div>
							<div className="checkout__form__input">
								<p>
									Town/City <span>*</span>
								</p>
								<input
									defaultValue={selectedAddress?.city}
									{...register("shippingcity", {
										required: true,
										maxLength: 20,
									})}
								/>
								{errors.shippingcity?.type === "required" &&
									"First name is required"}
							</div>
							<div className="checkout__form__input">
								<p>
									Country/State <span>*</span>
								</p>
								<input
									defaultValue={selectedAddress?.state}
									{...register("shippingstate", {
										required: true,
										maxLength: 20,
									})}
								/>
								{errors.shippingstate?.type === "required" &&
									"First name is required"}
							</div>
							<div className="checkout__form__input">
								<p>
									Postcode/Zip <span>*</span>
								</p>
								<input
									defaultValue={selectedAddress?.zipcode}
									{...register("shippingzipcode", {
										required: true,
										maxLength: 20,
									})}
								/>
								{errors.shippingzipcode?.type === "required" &&
									"First name is required"}
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-6">
							<div className="checkout__form__input">
								<p>
									Phone <span>*</span>
								</p>
								<input
									{...register("shippingmobile", {
										required: true,
										maxLength: 20,
									})}
								/>
								{errors.shippingmobile?.type === "required" &&
									"First name is required"}
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-6">
							<div className="checkout__form__input">
								<p>
									Email <span>*</span>
								</p>
								<input type="text" />
							</div>
						</div>
						{/* <div className="col-lg-12">
                        <div className="checkout__form__checkbox">
                            <label>
                                Create an acount?
                                <input type="checkbox" id="acc"/>
                                <span className="checkmark"></span>
                            </label>
                            <p>Create am acount by entering the information below. If you are a returing
                                customer login at the <br />top of the page</p>
                        </div>
                        <div className="checkout__form__input">
                            <p>Account Password <span>*</span></p>
                            <input type="text"/>
                        </div>
                        <div className="checkout__form__checkbox">
                            <label>
                                Note about your order, e.g, special noe for delivery
                                <input type="checkbox" id="note"/>
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        <div className="checkout__form__input">
                            <p>Oder notes <span>*</span></p>
                            <input type="text"
                            placeholder="Note about your order, e.g, special noe for delivery"/>
                        </div>
                    </div> */}
					</div>
				</div>
			</div>
		);
	};

	return (
		<section className="checkout spad">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<h6 className="coupon__link">
							<span className="icon_tag_alt"></span>{" "}
							<a href="#">Have a coupon?</a> Click here to enter your code.
						</h6>
					</div>
				</div>
				<form onSubmit={handleSubmit(onSubmit)} className="checkout__form">
					<div className="row">
						<BillingForm></BillingForm>
						<div className="col-lg-4">
							<div className="checkout__order" >
								<SideCart></SideCart>
								<b>Pay by</b>
								<select className="form-control"
									style={{ margin: "15px" }}
									id="paymentmode"
									onChange={(e) => {
										setPaymentMode(parseInt(e.target.value));
										//console.log(e.target.value)
										//setSelectedAddress(addresses?.find(el=>el.id===parseInt(e.target.value))??null)
									}}
									value={paymentMode}
								>
									<option value="0">Cash on Delivery</option>
									<option value="5025">Online Payment</option>
								</select>
								{false && (
									<div className="checkout__order__widget">
										<label>
											Create an acount?
											<input type="checkbox" id="o-acc" />
											<span className="checkmark"></span>
										</label>
										<p>
											Create am acount by entering the information below. If you
											are a returing customer login at the top of the page.
										</p>
										<label>
											Cheque payment
											<input type="checkbox" id="check-payment" />
											<span className="checkmark"></span>
										</label>
										<label>
											PayPal
											<input type="checkbox" id="paypal" />
											<span className="checkmark"></span>
										</label>
									</div>
								)}
								<button type="submit" className="site-btn">
									Place order
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</section>
	);
};

export default CheckoutComponent;
