import { updateProfile } from "api/General";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { assignLoggedInUser, loggedInUserStore } from "redux/slices/userSlice";
interface IFormInput {
    firstName: string;
    lastName: string;
    age: number;
    shippingstreet:string;
    shippinglocality:string;
    shippingcity:string;
    shippingstate:string;
    shippingzipcode:string;
    shippingmobile:string;
}

const ProfileComponent:React.FC = ()=>{
    const [isEdit,toggleEdit] = useState<boolean>(false)
    const { register,formState: { errors }, handleSubmit } = useForm<IFormInput>();
    const loggedInUser = useAppSelector(loggedInUserStore)
    const dispatch = useAppDispatch()
    const onSubmit: SubmitHandler<IFormInput> = async (data) => {
        let param:any = {id:loggedInUser.loggedInUser.id,firstname:data.firstName,lastname:data.lastName}
        
        const result = await updateProfile(param);
        dispatch(assignLoggedInUser({user:{...result.user},updateLocalStore:true}))
        toggleEdit(false)
    }
    return (
        <>
        
        <div className="col-xl-9 col-lg-8 col-md-12">
            <div className="dashboard-right">
                <div>
                    {isEdit && 
                    <form onSubmit={handleSubmit(onSubmit)} className="checkout__form">
                        <div className="checkout__form__input">
                            <p>First Name <span>*</span></p>
                            <input {...register("firstName",{ required: true, maxLength: 20 })} />
                            {errors.firstName?.type === 'required' && "First name is required"}
                        </div>
                        <div className="checkout__form__input">
                            <p>Last Name <span>*</span></p>
                            <input {...register("lastName",{ required: true, maxLength: 20 })} />
                            {errors.firstName?.type === 'required' && "First name is required"}
                        </div>
                        {/* <div className="checkout__form__input">
                            <p>Mobile Number <span>*</span></p>
                            <input type="number" {...register("lastName",{ required: true, maxLength: 10 })} />
                            {errors.firstName?.type === 'required' && "First name is required"}
                        </div> */}
                        <div className="flex-row flex-space-around">
                            <button type="button" className="site-btn2" onClick={()=>{
                                toggleEdit(false);
                            }}>Cancel</button>
                            <button type="submit" className="site-btn">Update</button>
                        </div>
                    </form>	
                    }	
                    {!isEdit && 
                        <div>
                            <div className="checkout__form__input">
                                <p>First Name </p>
                                <span className="details">{loggedInUser.loggedInUser.firstname}</span>
                            </div>
                            <div className="checkout__form__input">
                                <p>Last Name </p>
                                <span className="details">{loggedInUser.loggedInUser.lastname}</span>
                            </div>
                            <div className="checkout__form__input">
                                <p>Mobile Number </p>

                                

                                <span className="details">{}</span>
                            </div>
                            <div className="flex-row-end">
                                <button type="button" className="site-btn" onClick={()=>{
                                    toggleEdit(true);
                                }}>Edit</button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
        
        </>
    )
}

export default ProfileComponent;