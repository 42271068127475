
import { fetchCategoryProducts } from "api/General"
import CartItemComponent from "components/cart/CartItemComponent"
import CategoryFilters from "components/category/CategoryFilters"
import CategoryProducts from "components/category/CategoryProducts"
import Footer from "components/footer/Footer"
import Header from "components/header/Header"
import BreadCrumb from "components/productdetail/BreadCrumb"
import MainLayout from "layout/MainLayout"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { setProducts } from "redux/slices/categorySlice"


const CategoriesPage:React.FC = ()=>{
    
    const [called,setCalled] = useState(false)
    const dispatch = useDispatch();

    const params = useParams();

    const fetchData = async () => {
        const payload = {categoryurl:params.categoryurl}
        const res: any = await fetchCategoryProducts(payload);
        //console.log(res)
        if (res?.success) {
            console.log("Calling the reducer")
            console.log(res.data)
            //setBanners(res.data)
            dispatch(setProducts({ products: res.data.products }));
        } else {
            console.log("show error here");
        }
        setCalled(false)
    }

    useEffect(()=>{
        console.log("Getting the category products")
    
        if(!called){
            setCalled(true);
            fetchData();
        }
            
    },[params.categoryurl]);

    return (
        <MainLayout>
            <Header></Header>
            <BreadCrumb></BreadCrumb>
            <section className="shop spad">
                <div className="container">
                    <div className="row">
                    <CategoryFilters></CategoryFilters>
                    <CategoryProducts></CategoryProducts>
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </MainLayout>
    )
}

export default CategoriesPage
