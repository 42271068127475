

const CategoryFilters : React.FC = () => {
    return (
        <div className="col-lg-3 col-md-3">
            <div className="shop__sidebar">
                <div className="sidebar__categories">
                    <div className="section-title">
                        <h4>Categories</h4>
                    </div>
                    <div className="categories__accordion">
                        <div className="accordion" id="accordionExample">
                            <div className="card">
                                <div className="card-heading active">
                                    <a data-toggle="collapse" data-target="#collapseOne">Women</a>
                                </div>
                                <div id="collapseOne" className="collapse show" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <ul>
                                            <li><a href="#">Coats</a></li>
                                            <li><a href="#">Jackets</a></li>
                                            <li><a href="#">Dresses</a></li>
                                            <li><a href="#">Shirts</a></li>
                                            <li><a href="#">T-shirts</a></li>
                                            <li><a href="#">Jeans</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-heading">
                                    <a data-toggle="collapse" data-target="#collapseTwo">Men</a>
                                </div>
                                <div id="collapseTwo" className="collapse" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <ul>
                                            <li><a href="#">Coats</a></li>
                                            <li><a href="#">Jackets</a></li>
                                            <li><a href="#">Dresses</a></li>
                                            <li><a href="#">Shirts</a></li>
                                            <li><a href="#">T-shirts</a></li>
                                            <li><a href="#">Jeans</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-heading">
                                    <a data-toggle="collapse" data-target="#collapseThree">Kids</a>
                                </div>
                                <div id="collapseThree" className="collapse" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <ul>
                                            <li><a href="#">Coats</a></li>
                                            <li><a href="#">Jackets</a></li>
                                            <li><a href="#">Dresses</a></li>
                                            <li><a href="#">Shirts</a></li>
                                            <li><a href="#">T-shirts</a></li>
                                            <li><a href="#">Jeans</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-heading">
                                    <a data-toggle="collapse" data-target="#collapseFour">Accessories</a>
                                </div>
                                <div id="collapseFour" className="collapse" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <ul>
                                            <li><a href="#">Coats</a></li>
                                            <li><a href="#">Jackets</a></li>
                                            <li><a href="#">Dresses</a></li>
                                            <li><a href="#">Shirts</a></li>
                                            <li><a href="#">T-shirts</a></li>
                                            <li><a href="#">Jeans</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-heading">
                                    <a data-toggle="collapse" data-target="#collapseFive">Cosmetic</a>
                                </div>
                                <div id="collapseFive" className="collapse" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <ul>
                                            <li><a href="#">Coats</a></li>
                                            <li><a href="#">Jackets</a></li>
                                            <li><a href="#">Dresses</a></li>
                                            <li><a href="#">Shirts</a></li>
                                            <li><a href="#">T-shirts</a></li>
                                            <li><a href="#">Jeans</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sidebar__filter">
                    <div className="section-title">
                        <h4>Shop by price</h4>
                    </div>
                    <div className="filter-range-wrap">
                        <div className="price-range ui-slider ui-corner-all ui-slider-horizontal ui-widget ui-widget-content"
                        data-min="33" data-max="99"></div>
                        <div className="range-slider">
                            <div className="price-input">
                                <p>Price:</p>
                                <input type="text" id="minamount"/>
                                <input type="text" id="maxamount"/>
                            </div>
                        </div>
                    </div>
                    <a href="#">Filter</a>
                </div>
                <div className="sidebar__sizes">
                    <div className="section-title">
                        <h4>Shop by size</h4>
                    </div>
                    <div className="size__list">
                        <label>
                            xxs
                            <input type="checkbox" id="xxs"/>
                            <span className="checkmark"></span>
                        </label>
                        <label>
                            xs
                            <input type="checkbox" id="xs"/>
                            <span className="checkmark"></span>
                        </label>
                        <label>
                            xs-s
                            <input type="checkbox" id="xss"/>
                            <span className="checkmark"></span>
                        </label>
                        <label>
                            s
                            <input type="checkbox" id="s"/>
                            <span className="checkmark"></span>
                        </label>
                        <label>
                            m
                            <input type="checkbox" id="m"/>
                            <span className="checkmark"></span>
                        </label>
                        <label>
                            m-l
                            <input type="checkbox" id="ml"/>
                            <span className="checkmark"></span>
                        </label>
                        <label>
                            l
                            <input type="checkbox" id="l"/>
                            <span className="checkmark"></span>
                        </label>
                        <label>
                            xl
                            <input type="checkbox" id="xl"/>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div className="sidebar__color">
                    <div className="section-title">
                        <h4>Shop by size</h4>
                    </div>
                    <div className="size__list color__list">
                        <label>
                            Blacks
                            <input type="checkbox" id="black"/>
                            <span className="checkmark"></span>
                        </label>
                        <label>
                            Whites
                            <input type="checkbox" id="whites"/>
                            <span className="checkmark"></span>
                        </label>
                        <label>
                            Reds
                            <input type="checkbox" id="reds"/>
                            <span className="checkmark"></span>
                        </label>
                        <label>
                            Greys
                            <input type="checkbox" id="greys"/>
                            <span className="checkmark"></span>
                        </label>
                        <label>
                            Blues
                            <input type="checkbox" id="blues"/>
                            <span className="checkmark"></span>
                        </label>
                        <label>
                            Beige Tones
                            <input type="checkbox" id="beige"/>
                            <span className="checkmark"></span>
                        </label>
                        <label>
                            Greens
                            <input type="checkbox" id="greens"/>
                            <span className="checkmark"></span>
                        </label>
                        <label>
                            Yellows
                            <input type="checkbox" id="yellows"/>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default CategoryFilters;