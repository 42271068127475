import { useEffect, useState } from "react";
import { useAppDispatch } from "redux/hooks";
import { removeCartItem, updateCartItem, updateTotal } from "redux/slices/cartSlice";
import { CartRowType } from "./CartItemComponent";

const imagewidth = 90;
const imageheight = 90;

const CartRow = (props:CartRowType) => {
        
    const [quantity,setQuantity]= useState<number>(props.cartrow.qty)
    const [netprice,setNetPrice]= useState<number>(0)

    const dispatch = useAppDispatch()

    
    useEffect(()=>{
        let sp:number = props.cartrow.product.sp??0
        setNetPrice(sp * quantity)
        console.log("SP:"+sp)
        console.log("Net price:"+netprice)
    },[quantity])
    const imageobj = props.cartrow?.product?.images?.find(function(item:any){
        if(item.width===imagewidth && item.height===imageheight){
            return true
        }
        return false
    })
    return (
        <tr className="cart_item">
            <td className="cart__product__item">
                <img src={imageobj?.fullurl} alt="" width={imagewidth} height={imageheight}/>
                <div className="cart__product__item__title">
                    <h6>{props.cartrow.product.name}</h6>
                    <div className="rating">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                    </div>
                </div>
            </td>
            <td className="cart__price">{props.cartrow.product.sp}</td>
            <td className="cart__quantity">
                <div className="pro-qty">
                    <input type="number"  value={quantity} onChange={(e)=>{
                        setQuantity(parseInt(e.target.value) || 0)
                        
                        dispatch(
                            updateCartItem(
                                {product:{...props.cartrow.product},
                                qty:parseInt(e.target.value) || 0
                                }
                            )
                        )
                    }}/>
                </div>
            </td>
            <td className="cart__total">{netprice}</td>
            <td className="cart__close"><span className="icon_close" onClick={()=>{
                    dispatch(
                        removeCartItem(
                            {
                                product:{...props.cartrow.product}, qty:0
                            }
                        )
                    )
                }
            }></span></td>
        </tr>
    )
}

export default CartRow;