export const LocalRoutes = {
    general: {
      login: "/login",
      homePage: "/",
      cart: "/cart",
      checkout: "/checkout",
      register:"/register",
      product: ":producturl",
      category: "category/:categoryurl",
      confirmOrder:"/order/success"
    },
    user: {
      profile: "/profile",
      orders: "/orders",
      account: "/account",
      addresses: "/addresses",
      dashboard: "/dashboard",
      logout: "/logout",
    },
    
  };
  