import MenuLink from "components/homepage/menu/MenuLink";
import { NavItems, UserProfileMenu } from "components/homepage/MenuList";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LocalRoutes } from "utils/LocalRoutes";


type UserPageMenuProps = {
    pageurl?:String;
}

const UserPageMenu:React.FC<UserPageMenuProps> = ({pageurl})=>{

    
    
    const [activeMenu, setActiveMenu] = useState(UserProfileMenu[0]);

    const handleMenuOpen = (data:NavItems) => {
        setActiveMenu(data)
        navigate(data.href??'#')
    };

    useEffect(()=>{
        let defaultOption:NavItems =  UserProfileMenu.find(el=>el.href===pageurl?.replaceAll("/",""))??UserProfileMenu[0]
        console.log("STage1")
        console.log(defaultOption)
        handleMenuOpen(defaultOption)
    },[])

    const [profileMenu, setProfileMenu] = useState<any[]>(
        UserProfileMenu.map((el) => (
            <MenuLink
                key={el.menuitemid}
                data={el}
                onclick={handleMenuOpen}
                activeitem={activeMenu}
            />
            )
        )
    );

    useEffect(() => {
        setProfileMenu(
            UserProfileMenu.map((el) => (
                <MenuLink
                key={el.menuitemid}
                data={el}
                onclick={handleMenuOpen}
                activeitem={activeMenu}
            />
          ))
        );
    }, [activeMenu]);

    const navigate = useNavigate();
    

    return (
        <div className="col-xl-3 col-lg-4 col-md-12">
            <div className="left-side-tabs">
                <div className="dashboard-left-links">
                    {profileMenu}
                    
                </div>
            </div>
        </div>
    )
}

export default UserPageMenu;