
export type SiteDataMenuType = {
  name: string;
  href: string;
  submenu:SiteDataMenuType[];
};

export type BannerTypes = {
  banner1:number;
  banner2:number;
}
export type CategoryTypes = {
  trending:number;
  hotselling:number;
  fetured:number
}

export type RazorPay={
  id:number;
}

export type SiteDataType = {
  sitename:string;
  headerlogourl:string;
  topmenu:SiteDataMenuType[];
  config:BannerTypes;
  category:CategoryTypes;
  razorpayid:RazorPay;
}


export const siteData:SiteDataType = 
{
  sitename: "Dwain India",
  headerlogourl: "https://dwainindiaimages.s3.ap-south-1.amazonaws.com/site/dwainlogo.png",
  topmenu : [
    {
      name:"Home",
      href:"#",
      submenu:[]
    },
    {
      name:"Grocery",
      href:"#",
      submenu:[
        {
          name:"Fresh Vegetables",
          href:"/category/fresh-vegetables",
          submenu:[]
        },
        {
          name:"Fresh Fruits",
          href:"/category/fresh-fruits",
          submenu:[]
        },
        {
          name:"Dairy & Bakery",
          href:"/category/dairy-bakery",
          submenu:[]
        },
        {
          name:"Food Grains & Staples",
          href:"/category/food-grains-staples",
          submenu:[]
        },
        {
          name:"Snacks & Packed Foods",
          href:"/category/snacks-packed-foods",
          submenu:[]
        },
        {
          name:"Edible Oil & Spices",
          href:"/category/edible-oil-and-spices",
          submenu:[]
        },
        {
          name:"All Food & Gourmet",
          href:"/category/all-food-and-gourmets",
          submenu:[]
        },
        {
          name:"Dry Fruit & Other",
          href:"/category/dry-fruit-other",
          submenu:[]
        }
      ]
    },
    
    {
      name:"Clothing",
      href:"#",
      submenu:[
        {
          name:"Mens",
          href:"/category/mens",
          submenu:[]
        },
        {
          name:"Womens",
          href:"/category/womens",
          submenu:[]
        },
        {
          name:"Kids",
          href:"/category/kids",
          submenu:[]
        },
      ]
    },
    {
      name:"Home Essentials",
      href:"#",
      submenu:[
        {
          name:"Home Decor",
          href:"/category/home-decor",
          submenu:[]
        },
        {
          name:"Handmade",
          href:"/category/handmade",
          submenu:[]
        },
        {
          name:"Curtains & Pillow",
          href:"/category/curtains-pillows",
          submenu:[]
        },
        {
          name:"Furniture",
          href:"/category/furniture",
          submenu:[]
        },
      ]
    },
    {
      name:"Contact",
      href:"/category/cosmetics",
      submenu:[]
    },
  ],
  config:{
    banner1:1,
    banner2:2
  },
  category:{
    trending:39,
    hotselling:40,
    fetured:41
  },
  razorpayid:{
    id:5025
  }
}

