import ProductCard from "components/products/ProductCard";
import { useAppSelector } from "redux/hooks";
import { relatedProductsSlice } from "redux/slices/productSlice";


const RelatedProducts:React.FC = ()=>{

    const relatedProducts = useAppSelector(relatedProductsSlice)



    return (
        
        <div className="container">
        <div className="row">
            <div className="col-lg-12 text-center">
                <div className="related__title">
                    <h5>RELATED PRODUCTS</h5>
                </div>
            </div>
            {
                relatedProducts.map((el)=>(
                    
                    <ProductCard
                        key={el.id}
                        data={el}
                        imagewidth={270}
                        imageheight={360}
                    />
                ))
            }
        </div>
        </div>
        
    );
}

export default RelatedProducts;