import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrderTransactionsType, OrderTransactionType, OrderType } from "components/products/ProductType";
import { RootState } from "redux/store";

type OrdersType = {
    orders:OrderType[]
}

const initialState:OrdersType = {
    orders:[]
}

export const ordersSlice = createSlice({
    name:"orders",
    initialState,
    reducers:{
        assignOrderTransactions(state,action:PayloadAction<OrdersType>){
            state.orders = action.payload.orders
        }
    }
})

export const {assignOrderTransactions} = ordersSlice.actions;

export const orders = (state:RootState) => state.orders.orders

export default ordersSlice.reducer