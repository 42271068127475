import CryptoJS from "crypto-js";
const SALT: any = process.env.REACT_APP_SALT;

export const decryptAESData = (encryptedData: any) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, SALT);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const encryptAESData = (data: object) => {
  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    SALT //salt key
  ).toString();
  return encryptedData;
};

export const encryptAESString = (data: string) => {
  const encryptedData = CryptoJS.AES.encrypt(
    data,
    SALT //salt key
  ).toString();
  return encryptedData;
};

export const decryptAESString = (encryptedData: any) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, SALT);
  return bytes.toString(CryptoJS.enc.Utf8);
};