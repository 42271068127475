import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Locale } from "utils/LocaleEN";

const NotFoundComponent = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row", lg: "row" },
          flex: 1,
          justifyContent: "center",
          alignItems: { xs: "start", md: "center", lg: "center" },
          py: { xs: 1, md: 5, lg: 5 },
          pl: { xs: 2, md: 10, lg: 15 },
        }}
      >
        <Box
          sx={{
            flex: 2,
            display: "block",
            msOverflowY: 1,
            ml: 4,
            borderLeft: "10px solid #BFF0D4",
            px: 2,
            py: 3,
            maxWidth: 420,
          }}
        >
          <Typography
            variant="h1"
            style={{
              fontFamily: "IBM Plex Mono",
              fontWeight: 600,
              fontSize: 96,
            }}
          >
            {Locale.general.error.page404.title}
          </Typography>
          <Box
            sx={{
              fontFamily: "Plus Jakarta Sans",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            {Locale.general.error.page404.message}
          </Box>
        </Box>
        <Box
          component={"img"}
          sx={{
            content:
              "url('https://imagedelivery.net/LbPLnWIIJf3WvIoMOISJQg/d60f1a88-5118-4582-18b9-48b45e081100/public')",
            justifyContent: "center",
            mx: { xs: 1, md: 10, lg: 10 },
            objectFit: "contain",
            maxWidth: { xs: 400, lg: 642 },
          }}
        />
      </Box>
    );
}

export default NotFoundComponent;