export const Constants = {
  localStorage: {
    session: "user",
    accessToken: "accesstoken"
  },
  
  environment: {
    development: "dev",
    production: "production",
  },

  userpages: {
    orders: "orders",
    profile: "profile",
    addresses:"addresses"
  },

}

export const config = {
  firebase: {
      apiKey: process.env.REACT_APP_FIREBASE_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
      //databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  }
}