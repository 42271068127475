import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductType } from "components/products/ProductType";
import { RootState } from "redux/store";
import { getLocalStorageData, setLocalStorageData } from "utils/LocalStorage";

export type CartItem = {
    product:Partial<ProductType>;
    qty:number;
}

type CartType = {
    items:CartItem[],
    finalamount:number
}

const initialState:CartType = {
    items:[],//getLocalStorageData('cart')??[],
    finalamount:0//getLocalStorageData('finalamount')??0,
}

export const cartSlice = createSlice({
    name:"cart",
    initialState,
    reducers:{
        addProductToCart(state,action:PayloadAction<CartItem>){
            //check if same product with all attributes are present
            //if not present then add a new row
            //otherwise update the quantity
            console.log(state.items)
            const cartitem = state.items.find(el=>el.product.id===action.payload.product.id)
            if(typeof cartitem!=='undefined'){
                cartitem.qty += action.payload.qty
                //call the update quantity api
                console.log("Stage1")
            } else {
                const cartitem = {qty:action.payload.qty,product:action.payload.product}
                state.items.push(cartitem)
                //call the add product api
                console.log("Stage2")
            }
            let total = 0;
            state.items.forEach((el)=>{
                var sp:number = el.product?.sp??0
                var qty:number = el.qty
                var net = sp * qty
                total = +total + +net
            })
            state.finalamount = total;
            setLocalStorageData('cart', state.items )
            
            setLocalStorageData('finalamount', state.finalamount )
            //update the server cart
            
        },
        updateCartItem(state,action:PayloadAction<CartItem>){
            const cartitem = state.items.find(el=>el.product.id===action.payload.product.id)
            if(typeof cartitem!=='undefined'){
                cartitem.qty = action.payload.qty
                //call the update quantity api
            } else {
                //call the add product api
            }
            
            let total = 0;
            state.items.forEach((el)=>{
                var sp:number = el.product?.sp??0
                var qty:number = el.qty
                var net = sp * qty
                total = +total + +net
                //console.log("multipl"+(parseFloat((el.product?.sp??0).toString) * parseFloat(el.qty)))
                
                
            })
            console.log(total)
            state.finalamount = total;
            setLocalStorageData('cart', state.items )
            
            setLocalStorageData('finalamount', state.finalamount )
        },
        removeCartItem(state,action:PayloadAction<CartItem>){
            console.log("Stage1")
            const index = state.items.findIndex(el=>el.product.id===action.payload.product.id)
            if(index>-1){
                state.items.splice(index,1)
                //remove the product from the server
            }
            console.log("Stage2")
            let total = 0;
            state.items.forEach((el)=>{
                var sp:number = el.product?.sp??0
                var qty:number = el.qty
                var net = sp * qty
                total = +total + +net
                //console.log("multipl"+(parseFloat((el.product?.sp??0).toString) * parseFloat(el.qty)))
                
                
            })
            console.log(total)
            state.finalamount = total;
            setLocalStorageData('cart', state.items )
            
            setLocalStorageData('finalamount', state.finalamount )
        },
        updateTotal(state){
            console.log("Calculating final amount")
            
        },
        setResetItems(state,action:PayloadAction<CartItem[]>){
            state.items = action.payload;
            let total = 0;
            state.items.forEach((el)=>{
                var sp:number = el.product?.sp??0
                var qty:number = el.qty
                var net = sp * qty
                total = +total + +net
            })
            state.finalamount = total;
            setLocalStorageData('cart', state.items )
            setLocalStorageData('finalamount', state.finalamount )
        }
    }
})

export const { addProductToCart, updateTotal,updateCartItem,setResetItems, removeCartItem } = cartSlice.actions

export const items = (state:RootState) => state.cart.items
export const finalamountSlice = (state:RootState) => state.cart.finalamount

export default cartSlice.reducer;