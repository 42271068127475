import CheckoutComponent from "components/checkout/CheckoutComponent";
import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import MainLayout from "layout/MainLayout";


const CheckoutPage:React.FC = ()=>{
    return (
        <MainLayout>
            <Header></Header>
            <CheckoutComponent></CheckoutComponent>
            <Footer></Footer>
        </MainLayout>
    );
}

export default CheckoutPage;