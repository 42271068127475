import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import LoginComponent from "components/login/LoginComponent";
import MainLayout from "layout/MainLayout"


const LoginPage: React.FC = () => {
    /*useEffect(() => {
      trackPageView(PageCategory.registration, Routes.general.login);
    }, []);
    */
  
    return (
        <MainLayout>
          <Header></Header>
          <LoginComponent></LoginComponent>
          <Footer></Footer>
        </MainLayout>
    );
  };
  
//export default profiler(LoginPage);
export default LoginPage;