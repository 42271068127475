
import { firebaseLogin } from "api/General";
import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { assignLoggedInUser, loggedInUserStore } from "redux/slices/userSlice";
import { Constants } from "utils/Constants";
import Firebase, { auth,firebase } from "utils/Firebase";
import { LocalRoutes } from "utils/LocalRoutes";
import { setLocalStorageData, setLocalStorageString } from "utils/LocalStorage";
import { useNavigate } from "react-router-dom";

interface IFormInput {
    email: string;
    mobileno:string;
}

const LoginComponent:React.FC = ()=>{

    const { register, handleSubmit } = useForm<IFormInput>();
    const [mynumber, setnumber] = useState("");
    const [telecode, settelecode] = useState("+91");
    const [otp, setotp] = useState('');
    const [show, setshow] = useState(false);
    const [final, setfinal] = useState<any>(null);
    const [error, setError] = useState<string|null>(null);
    const[responsedata,setresponsedata]=useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const signin = () => {
        
        if (mynumber === "" || mynumber.length < 10){
            setError("Mobile No should be minimum of 10 digits")
            return;
        } 
        let verify = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            'callback': (response:any) => {
              console.log("prepared phone auth process");
              console.log(response)
              setresponsedata(true);
            }
          });
        console.log(telecode+mynumber)
        auth.signInWithPhoneNumber(telecode+mynumber, verify).then((result) => {
            setfinal(result);
            alert("code sent")
            setshow(true);
        })
        .catch((err) => {
            setError(err.toString())
            // window.location.reload()
        });
    }
  
    // Validate OTP
    const ValidateOtp = () => {
        if (otp === null || final === null)
            return;
        final.confirm(otp).then(async (response:any) => {
            // success
            alert("Verified")
            console.log(response)
            response.user.getIdToken().then(async (idToken:string)=> {
                console.log(idToken);
                //send the token to the server and get the user details
                //after getting the user details
                //if there is something in the cart then update the cart for the user
                const res = await firebaseLogin({firebasetoken:idToken});
                console.log("Sending firebase token")
                console.log(res)
                dispatch(assignLoggedInUser({user:res.user,updateLocalStore:true}))
                setLocalStorageString(Constants.localStorage.accessToken,res.access_token);
                //dispatch(assignAccessToken({accessToken:res.user,updateLocalStore:true}))
                navigate({pathname:LocalRoutes.general.homePage});
            })
            
        }).catch(() => {
            
            setError("Code not Correct")
        })
    }
  
    return (
        <section className="shop-cart spad">
        <div className="container checkout__form">
            <h5 style={{textAlign:"center"}}>Login Details</h5>
            <div className="row">
            {  !responsedata &&
                <div className="col-lg-12 col-md-12 col-sm-12">
               
                    <div className="checkout__form__input" style={{marginBottom:"0px"}}>
                    
                        <input type="text" value={telecode} style={{width:"5%",borderRadius:"0px"}} 
                        onChange={(e) => { 
                            settelecode(e.target.value) 
                            setError(null)
                        }}/>
                        <input value={mynumber} style={{width:"30%",borderRadius:"0px"}} onChange={(e) => { 
                        setnumber(e.target.value) 
                        setError(null)
                        }}
                            placeholder="Enter 10 Digit Valid Mobile No." />
                        
                    </div>
                    <div style={{color:"red"}} className="checkout__form__input">{error && error}</div>
                    </div>
                 }
           
            </div>
            <div className="row">
            {  !responsedata &&
                <div className="col-lg-12 col-md-12 col-sm-12 checkout__form__input" style={{flexDirection: "column"}}>
                    <div id="recaptcha-container"></div>
                    <button className="site-btn" type="button" onClick={signin}>Send OTP</button>
                </div>}
                {  responsedata &&
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div style={{ display: show ? "row" : "block" }}>
                        <div className="checkout__form__input">
                            
                            <input type="text" placeholder={"Enter your OTP"} style={{width:"30%"}}
                            onChange={(e) => { setotp(e.target.value) }}></input>
                        </div>
                        
                        <div className="col-lg-12 col-md-12 col-sm-12 checkout__form__input">
                            <button className="site-btn" onClick={ValidateOtp}>Verify</button>
                        </div>
                        
                    </div>    
                </div>}
            </div>
        </div>
        </section>
    );

}

export default LoginComponent;