import { submitAddress } from "api/General";
import { AddressType } from "components/products/ProductType";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch } from "redux/hooks";
import { updateappendAddress } from "redux/slices/userSlice";

type AddressFormProp = {
    address:AddressType
    closeFunction:any
}

const AddressForm:React.FC<AddressFormProp> = ({address,closeFunction})=>{

    const { register,formState: { errors }, handleSubmit } = useForm<AddressType>();
    const dispatch = useAppDispatch()
    const onSubmit: SubmitHandler<AddressType> = async (data) => {
        
        const result = await submitAddress(data);
        console.log(result)
        /*if (!result && result.status) {
            alert("Server error. Are you online?");
            return;
        }
        */
        //update the address in the address list
        dispatch(updateappendAddress(data))
        closeFunction(false)
        

    }


    return (
        <div className="add-address-form">
            <div className="checout-address-step">
                <div className="row">
                    <div className="col-lg-12">												
                        <form className="" onSubmit={handleSubmit(onSubmit)}>
                            
                            {/* <div className="form-group">
                                <div className="product-radio">
                                    <ul className="product-now">
                                        <li>
                                            <input type="radio" id="ad1" name="address1" checked/>
                                            <p>Home</p>
                                        </li>
                                        <li>
                                            <input type="radio" id="ad2" name="address1"/>
                                            <p>Office</p>
                                        </li>
                                        <li>
                                            <input type="radio" id="ad3" name="address1"/>
                                            <p>Other</p>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}
                            <div className="address-fieldset">
                                <div className="row checkout__form">
                                    <div className="col-lg-12 col-md-12 ">
                                        <div className="checkout__form__input">
                                            <p className="control-label">Flat / House / Office No.*</p>
                                            <input type="hidden" defaultValue={address.id} {...register("id")} />
                                            <input defaultValue={address.houseno} {...register("houseno",{ required: true, maxLength: 20, })} />
                                            <span className="red">{errors.houseno?.type === 'required' && "House No is required"}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="checkout__form__input">
                                            <p className="control-label">Street / Society / Office Name*</p>
                                            <input defaultValue={address.street} {...register("street",{ required: true, maxLength: 20, })} />
                                            <span className="red">{errors.street?.type === 'required' && "Street is required"}</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="checkout__form__input">
                                            <p className="control-label">Locality*</p>
                                            <input defaultValue={address.locality} {...register("locality",{ required: true, maxLength: 20, })} />
                                            <span className="red">{errors.locality?.type === 'required' && "Locality is required"}</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="checkout__form__input">
                                            <p className="control-label">City*</p>
                                            <input defaultValue={address.city} {...register("city",{ required: true, maxLength: 20, })} />
                                            <span className="red">{errors.city?.type === 'required' && "City is required"}</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="checkout__form__input">
                                            <p className="control-label">State*</p>
                                            <input defaultValue={address.state} {...register("state",{ required: true, maxLength: 20, })} />
                                            <span className="red">{errors.state?.type === 'required' && "State is required"}</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="checkout__form__input">
                                            <p className="control-label">Pincode*</p>
                                            <input defaultValue={address.zipcode} {...register("zipcode",{ required: true, maxLength: 20, })} />
                                            <span className="red">{errors.zipcode?.type === 'required' && "Zipcode is required"}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-12 ">
                                        <div className="flex-row flex-space-around">
                                            <button className="site-btn2" onClick={
                                                ()=>{
                                                    closeFunction(false)
                                                }
                                            }>Cancel</button>
                                            <button type="submit" className="site-btn">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddressForm;