import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import AddressComponent from "components/user/AddressComponent";
import OrdersComponent from "components/user/OrdersComponent";
import ProfileComponent from "components/user/ProfileComponent";
import UserPageMenu from "components/user/UserPageMenu";
import MainLayout from "layout/MainLayout";
import { useEffect, useState } from "react";
import { Constants } from "utils/Constants";

type UserProp = {
    page:string
}

const UserPage:React.FC<UserProp> = ({page})=>{

    const [selectedElement,setSelectedElement] = useState<any>()

    useEffect(()=>{
        let localSelectedElement;
        switch(page){
            case Constants.userpages.orders:
                localSelectedElement=<OrdersComponent/>
                break;
            case Constants.userpages.profile:
                localSelectedElement=<ProfileComponent/>
                break;
            case Constants.userpages.addresses:
                localSelectedElement=<AddressComponent/>
                break;
        }
        setSelectedElement(localSelectedElement)
    },[page])

    return (
        <MainLayout>
            <Header></Header>
            <section className="product-details spad">
                <div className="container">
                    <div className="row">
                        <UserPageMenu pageurl={page}></UserPageMenu>
                        {selectedElement}
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </MainLayout>
    )
}

export default UserPage;