import { useState } from "react";
import { useAppSelector } from "redux/hooks";
import { selectedProductSlice } from "redux/slices/productSlice";


const ProductTabDescription:React.FC = () =>{

    const [activeTab,setActiveTab] = useState(1)

    const selectedProduct = useAppSelector(selectedProductSlice)

    const reviews = () => {
        return (
            <div></div>
        )
    }

    return (
        <div className="container">
        <div className="col-lg-12">
            <div className="product__details__tab">
                <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" data-toggle="tab" role="tab"
                            onClick={()=>{setActiveTab(1)}}
                        >Description</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" role="tab"
                            onClick={()=>{setActiveTab(2)}}
                        >Specification</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" role="tab"
                            onClick={()=>{setActiveTab(3)}}
                        >Reviews ( 2 )</a>
                    </li>
                </ul>
                <div className="tab-content">
                    <div className="tab-pane active" id="tabs-1" role="tabpanel">
                        <h6>{
                            activeTab==1?'Description':activeTab==2?'Specification':'Reviews'
                        }</h6>
                        {activeTab==1?selectedProduct.description
                            :activeTab==2?selectedProduct.specification
                            :reviews()}
                        
                    </div>
                    
                </div>
            </div>
        </div>
        </div>
    )
}

export default ProductTabDescription;