import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BannerType } from "components/homepage/topsection/TopSection";
import type { RootState } from "redux/store";

export type BannerState = {
  banners: BannerType[];
  
};

const initialState: BannerState = {
  banners: []
};

export const bannerSlice = createSlice({
  name: "banners",
  initialState,
  reducers: {
    updateBanners: (state, action: PayloadAction<BannerState>) => {
      console.log("Hello")
      state.banners = action.payload.banners;
      
      //console.log("Inside reducer")
      //console.log(action.payload.products)
      //console.log(state.products);
    },
    
    clearResults() {
      // persistor.purge();
      // Note that this should be left intentionally empty.
      // Clearing redux state and localForage happens in rootReducer.ts.
    },
  },
});
export const {
    updateBanners,
} = bannerSlice.actions;


export const banners = (state:RootState) => state.banners.banners
/*export const stepNumber = (state: RootState) => state.registration.stepNumber;
export const companyDetailsFilled1 = (state: RootState) =>
  state.registration.companyDetailsFilled1;
export const companyInfo = (state: RootState) =>
  state.registration.companyInfo || {};
export const userInfo = (state: RootState) => state.registration.userInfo || {};
*/
/*export const getUserObject =
  (state: RootState) =>
  (userStatus: Status): object => {
    let userProfile = { scopes: [""], userStatus } as any;
    const userInfo = state.registration.userInfo;
    userProfile = { ...userProfile, ...userInfo };
    userProfile.status = null;
    return { emailAddress: userInfo.emailAddress, userProfile };
  };
*/
/*export const getCompanyObject =
  (state: RootState) =>
  (status: Status): object => {
    const companyInfo = state.registration.companyInfo;
    const companyProfile = { ...companyInfo };
    const userProfile = getUserObject(state)(status);
    return {
      companyName: companyInfo.name,
      ...userProfile,
      companyProfile,
    };
  };
*/
export default bannerSlice.reducer;
