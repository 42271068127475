import { ReactElement } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { products } from "redux/slices/homepageProductsSlice";
import { updateProduct } from "redux/slices/productSlice";
import { LocalRoutes } from "utils/LocalRoutes";
import { ProductType } from "./ProductType";
import logo from "assets/template/img/logo/discount.jpg";
import { margin } from "@mui/system";
import { siteData } from "data";
type CategoryProps = {
    categoryid:number;
    title:string
}

type ProductProps = {
    product:ProductType;
    imageWidth:number;
    imageHeight:number
}

const ColumnsDisplay : React.FC = () => {

    const homepageProducts = useAppSelector(products);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const SmallProductCard = (props:ProductProps): ReactElement =>{



        const navigateToProduct = ()=>{
            dispatch(updateProduct({product:props.product}))
            navigate("/"+props.product?.url)
        }

        const imageobj = props.product?.images?.find(function(item:any){
            if(item.width===props.imageWidth && item.height===props.imageHeight){
                return true
            }
            return false
        })

        return (
                <div className="trend__item" onClick={()=>{
                        navigateToProduct()
                    }
                    }>
                    <div className="trend__item__pic">
                        <img src={
                            imageobj ? imageobj.fullurl: '#'
                        } alt="" width={props.imageWidth} height={props.imageHeight}/>
                    </div>
                    <div className="trend__item__text">
                        <h6>{props.product.name}</h6>
                        <div className="rating">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                        <div className="product__price">{props.product.sp}</div>
                    </div>
                </div>
        )
    }

    const CategoryProduct = (props:CategoryProps) : ReactElement => {
        const selectedCategoryProducts = homepageProducts.filter(function(item){
            let categoryids = item.categoryids.split(",")
            return categoryids.includes(props.categoryid.toString())
        });


        return (
                <div className="section-title">
                <h4 className="mb-35">{props.title}</h4>
                {selectedCategoryProducts.map((row:ProductType)=>(
                    <SmallProductCard
                        key={row.id}
                        product={row}
                        imageWidth={90}
                        imageHeight={90}
                    />
                ))}
                </div>
        )
    }
    const mystyle = {
        backgroundColor: "white",
        margin:"2px",
       // CSS CODE
       };

    return (
        <section className="trend spad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="trend__content">
                            <CategoryProduct categoryid={siteData.category.trending} title={"Trending"}/>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="trend__content">
                            <CategoryProduct categoryid={siteData.category.hotselling} title={"Hot Selling"}/>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="trend__content">
                            <CategoryProduct categoryid={siteData.category.fetured} title={"Featured"}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container" >
        {/* <div  className="row">
            <div  className="col-lg-4 p-0">
                <div style={mystyle}  className="discount__pic">
                    <img  src={logo} alt=""/>
                </div>
            </div>
            <div  className="col-lg-4 p-0">
                <div style={mystyle}  className="discount__pic">
                    <img  src={logo} alt=""/>
                </div>
            </div>
            <div  className="col-lg-4 p-0">
                <div  style={mystyle} className="discount__pic">
                    <img  src={logo} alt=""/>
                </div>
            </div>
            
        </div> */}
    </div>




        </section>
    );
}

export default ColumnsDisplay;