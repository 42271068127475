import { Locale } from "utils/LocaleEN";
import { LocalRoutes } from "utils/LocalRoutes";

export const UserProfileMenu: NavItems[] = [
    {
        title: Locale.menu.user.profile,
        href: LocalRoutes.user.profile,
        class:'user-item'
    },
    {
        title: Locale.menu.user.orders,
        href: LocalRoutes.user.orders,
        class:'user-item'
    },
    {
        title: Locale.menu.user.addressess,
        href: LocalRoutes.user.addresses,
        class:'user-item'
    },
    {
        title: Locale.menu.user.logout,
        href: LocalRoutes.user.logout,
        class:'user-item'
    },
];

export type NavItems = {
    
    title?: string;
    menuitemid?: number;
    href?:string;
    class?:string;
};

export const productMenuList: NavItems[] = [
    {
      title: Locale.menu.product.all,
      menuitemid: 0,
      class:''
    },
    {
        title: Locale.menu.product.women,
        menuitemid: 1,
        class:''
    },
    {
        title: Locale.menu.product.men,
        menuitemid: 2,
        class:''
    },
    {
        title: Locale.menu.product.kid,
        menuitemid: 3,
        class:''
    },
    {
        title: Locale.menu.product.accessories,
        menuitemid: 4,
        class:''
    },
    {
        title: Locale.menu.product.cosmetics,
        menuitemid: 5,
        class:''
    },
];