import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductType } from "components/products/ProductType";
import type { RootState } from "redux/store";

export type ProductState = {
  products: ProductType[];
  
};

const initialState: ProductState = {
  products: []
};

export const homepageProductsSlice = createSlice({
  name: "homepageProducts",
  initialState,
  reducers: {
    updateProducts: (state, action: PayloadAction<ProductState>) => {
      console.log("Hello")
      state.products = action.payload.products;
      //console.log("Inside reducer")
      //console.log(action.payload.products)
      //console.log(state.products);
    },
    
    clearResults() {
      // persistor.purge();
      // Note that this should be left intentionally empty.
      // Clearing redux state and localForage happens in rootReducer.ts.
    },
  },
});
export const {
  updateProducts,
} = homepageProductsSlice.actions;

export const products = (state: RootState) =>
  state.homepageProducts.products
/*export const stepNumber = (state: RootState) => state.registration.stepNumber;
export const companyDetailsFilled1 = (state: RootState) =>
  state.registration.companyDetailsFilled1;
export const companyInfo = (state: RootState) =>
  state.registration.companyInfo || {};
export const userInfo = (state: RootState) => state.registration.userInfo || {};
*/
/*export const getUserObject =
  (state: RootState) =>
  (userStatus: Status): object => {
    let userProfile = { scopes: [""], userStatus } as any;
    const userInfo = state.registration.userInfo;
    userProfile = { ...userProfile, ...userInfo };
    userProfile.status = null;
    return { emailAddress: userInfo.emailAddress, userProfile };
  };
*/
/*export const getCompanyObject =
  (state: RootState) =>
  (status: Status): object => {
    const companyInfo = state.registration.companyInfo;
    const companyProfile = { ...companyInfo };
    const userProfile = getUserObject(state)(status);
    return {
      companyName: companyInfo.name,
      ...userProfile,
      companyProfile,
    };
  };
*/
export default homepageProductsSlice.reducer;
