import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { LocalRoutes } from 'utils/LocalRoutes';
import { getLocalStorageString } from 'utils/LocalStorage';

const RequireAuth:React.FC = () => {
    let location = useLocation();
    let token = getLocalStorageString("accesstoken")
    console.log(token)
    console.log(token?'not null':'false')
    if(token){
        return <Outlet/>
    }
    return <Navigate to={LocalRoutes.general.login} state={{ from: location }} />;
};
export default RequireAuth;