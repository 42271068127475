
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductType } from "components/products/ProductType";
import { RootState } from "redux/store";

export type SelectedProduct = {
    product?:Partial<ProductType>;
    relatedProducts?:ProductType[];
}

const initialState:SelectedProduct = {
    product:{},
    relatedProducts:[]
}

export const productSlice = createSlice({
    name:"selectedProduct",
    initialState,
    reducers:{
        updateProduct(state,action:PayloadAction<SelectedProduct>){
            state.product = action.payload.product;
        },
        updateRelatedProducts(state,action:PayloadAction<SelectedProduct>){
            state.relatedProducts = action.payload.relatedProducts;
        }
    }
})
export const {
    updateProduct, updateRelatedProducts
} = productSlice.actions

export const selectedProductSlice = (state : RootState) => state.selectedproduct.product  || {}
export const relatedProductsSlice = (state : RootState) => state.selectedproduct.relatedProducts  || []

export default productSlice.reducer