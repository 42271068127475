
import CartItemComponent from "components/cart/CartItemComponent"
import Footer from "components/footer/Footer"
import Header from "components/header/Header"
import BreadCrumb from "components/productdetail/BreadCrumb"
import MainLayout from "layout/MainLayout"


const CartPage:React.FC = ()=>{
    
    

    return (
        <MainLayout>
            <Header></Header>
            <BreadCrumb></BreadCrumb>
            <CartItemComponent></CartItemComponent>
            <Footer></Footer>
        </MainLayout>
    )
}

export default CartPage
