import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AddressType, UserType } from "components/products/ProductType"
import { RootState } from "redux/store";
import { setLocalStorageData } from "utils/LocalStorage";

type LoggedInResponseType = {
    user:UserType;
    updateLocalStore?:boolean;
    accessToken?:String
}


type UserSliceType ={
    loggedInUser:UserType;
    accessToken?:String | null;
}

const initialState:UserSliceType={
    loggedInUser:{},
    accessToken:null
}


export const loggedInUserTypeSlice= createSlice({
    name:"loggedinuser",
    initialState,
    reducers:{
        assignLoggedInUser(state,action:PayloadAction<LoggedInResponseType>){
            state.loggedInUser = action.payload.user
            console.log(action.payload.user);
            if(action.payload.updateLocalStore){
                setLocalStorageData('loggedinuser', state.loggedInUser )
            }
        },
        updateappendAddress(state,action:PayloadAction<AddressType>){
            const addressIndex:number = state.loggedInUser?.addressess?.findIndex(el=>el.id===action.payload.id)??-1
            if(addressIndex>-1){
                state.loggedInUser?.addressess?.splice(addressIndex,1,action.payload)
            } else {
                if(state.loggedInUser?.addressess){
                    state.loggedInUser?.addressess?.push(action.payload)
                } else {
                    console.log("Pushing to addressess")
                    state.loggedInUser['addressess']=[]
                    state.loggedInUser?.addressess?.push(action.payload)
                    
                }
                
            }
        },
        setAddressess(state,action:PayloadAction<AddressType[]>){
            state.loggedInUser['addressess'] = action.payload
        }
    }
})

export const {assignLoggedInUser,updateappendAddress,setAddressess} = loggedInUserTypeSlice.actions;

export const loggedInUserStore = (state:RootState) => state.loggedInUserStore
export const addressessStore = (state:RootState) => state.loggedInUserStore.loggedInUser.addressess
export const accessToken = (state:RootState) => state.loggedInUserStore.accessToken

export default loggedInUserTypeSlice.reducer