import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { finalamountSlice, items, setResetItems } from "redux/slices/cartSlice";
import { getLocalStorageData } from "utils/LocalStorage";


const SideCart:React.FC = ()=>{

    const cartItems = useAppSelector(items)
    const dispatch = useDispatch()
    const finalamount = useAppSelector(finalamountSlice)
    const [cartRowArray,setCartRowArray] = useState<any[]>(
        /*cartItems.map((el,index)=>{
            <CartRow
                key={index}
                cartrow={el}
            />
        })*/
    )
    
    /*useEffect(()=>{
        console.log("Hello")
        console.log(cartItems);
        let rows:any[] = [];
        cartItems.forEach((el,index)=>{
            rows.push(<li key={index}>{el.product.name} <span>{el.product.sp}</span></li>)
        })
        setCartRowArray(rows)
        if(cartItems.length==0){
            let localcart = getLocalStorageData("cart")
            localcart = Object.values(localcart);
            dispatch(setResetItems(localcart))

        }

    },[
        cartItems
    ])*/

    return (
        <>
            <h5>Your order</h5>
            <div className="checkout__order__product">
                <ul>
                    <li>
                        <span className="top__text">Product</span>
                        <span className="top__text__right">Total</span>
                    </li>
                    {cartRowArray}
                </ul>
            </div>
            <div className="checkout__order__total">
                <ul>
                    <li>Subtotal <span>{finalamount}</span></li>
                    <li>Total <span>{finalamount}</span></li>
                </ul>
            </div>
        </>
    )
}

export default SideCart;