import { siteData, SiteDataMenuType } from "data"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { LocalRoutes } from "utils/LocalRoutes"

const DropDownMenu:React.FC = ()=>{
    
    const [selectedMenu,setSelectedMenu] = useState("Home")
    const navigate = useNavigate()
    //how to set the active menu
    const pathArray = window.location.pathname.split("/");
    const segment_1 = pathArray[1];
    useEffect(()=>{
        
        
        if(segment_1==''){
            
            setSelectedMenu("Home")
        }
        let found = siteData.topmenu.find(el=>el.name===segment_1)
        if(!found){
            siteData.topmenu.forEach((el:SiteDataMenuType)=>{
                let found = el.submenu.find(subEL=>subEL.name===segment_1)
                if(found){
                    setSelectedMenu(el.name)
                    
                }
            })
        }
    },[segment_1])

    return <ul>
        {
            siteData?.topmenu?.map((el:SiteDataMenuType)=>{
                if(el.submenu.length>0){
                    return <li className={selectedMenu==el.name?'active':''}><a href="#" onClick={
                            ()=>{
                                navigate(el.href)
                            }
                            }>{el.name}</a>
                            <ul className="dropdown">
                                {
                                    el.submenu.map((subel:SiteDataMenuType)=>{
                                        return <li><a href="#" onClick={
                                        ()=>{
                                            navigate(subel.href)
                                        }
                                        }>{subel.name}</a>
                                </li>
                                    })
                                }
                                
                            </ul>
                        </li>
                }
                return <li className={selectedMenu==el.name?'active':''}><a href="/">{el.name}</a></li>
            })
        }
        
        
    </ul>
}

export default DropDownMenu;