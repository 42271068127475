
  
const genericErrorMessage = "Something went wrong. Please try again";
  
export const Locale = {
    menu:{
        product:{
            all:"All",
            women:"Women's",
            men:"Men's",
            kid:"Kid's",
            accessories:"Accessories's",
            cosmetics:"Cosmetics"
        },
        user:{
            profile:"Profile",
            addressess:"Addressess",
            orders:"Orders",
            logout:"Logout",
        }
    },
    general:{
        product:{
            loading:"Loading"
        },
        error:{
            tryAgain:{
                title:"Try Again",
                message:"Message",
                cta:"CTA"
            },
            page404:{
                title:"Page Not Found",
                message:"Message",
            }
        }
    }
  };
  