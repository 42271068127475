
import CartPage from "pages/CartPage";
import CategoriesPage from "pages/CategoriesPage";
import CheckoutPage from "pages/CheckoutPage";
import HomePage from "pages/HomePage";
import LoginPage from "pages/LoginPage";
import OrderSuccessPage from "pages/OrderSuccessPage";
import ProductDetail from "pages/ProductDetail";
import UserPage from "pages/UserPage";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  Link,
  LinkProps,
  Outlet,
} from "react-router-dom";
import RequireAuth from "utils/RequiresAuth";

import { Constants } from "utils/Constants";

import { LocalRoutes } from "./utils/LocalRoutes";


export const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path={LocalRoutes.general.login} element={<LoginPage/>} />
        <Route path={LocalRoutes.general.register} element={<LoginPage/>} />
        <Route path={LocalRoutes.general.homePage} element={<HomePage/>} />
        <Route path={LocalRoutes.general.cart} element={<CartPage/>} />
        <Route path={LocalRoutes.general.checkout} element={<CheckoutPage/>} />
        <Route path={LocalRoutes.general.product} element={<ProductDetail/>} />
        <Route path={LocalRoutes.general.category} element={<CategoriesPage/>} />
        <Route path={LocalRoutes.general.confirmOrder} element={<OrderSuccessPage/>}/>
        <Route element={<RequireAuth/>}>
          <Route path={LocalRoutes.user.orders} 
            element={<UserPage 
              page={Constants.userpages.orders}/>
            }
          />
          <Route path={LocalRoutes.user.profile} 
            element={<UserPage 
              page={Constants.userpages.profile}/>
            }
          />
          <Route path={LocalRoutes.user.addresses} 
            element={<UserPage 
              page={Constants.userpages.addresses}/>
            }
          />
        </Route>
        
      </Routes>
    </Router>
    );
  };
  
  

  export default App;


